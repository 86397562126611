/* eslint-disable */
import React, { createRef, Component, Fragment } from 'react'
import { findDOMNode } from 'react-dom'
import { Root } from './Dropdown.Styled'

interface DropdownProps {
  children: any,
  handler: any,
  position?: 'left' | 'right',
}

interface DropdownState {
  style: {[key: string]: any},
  visible: boolean,
}

export class Dropdown extends Component<DropdownProps, DropdownState> {

	public static defaultProps = {
		position: 'left',
	}
	public dropdownRef: any

	constructor(props) {
		super(props)

		this.state = {
			style: {},
			visible: false,
		}

		this.dropdownRef = createRef()
	}

	public componentDidMount() {
		const handlerDOM = findDOMNode(this.props.handler.current) as Element

		handlerDOM.addEventListener('mouseenter', this.HandleMouseEnter)
	}

	public HandleMouseEnter = () => {
		const handlerDOM = findDOMNode(this.props.handler.current) as Element

		this.ToggleVisibility(true)

		this.UpdateDropdownPosition()

		handlerDOM.addEventListener('mouseleave', this.HandleMouseLeave)
	}

	public HandleMouseLeave = (event: MouseEvent) => {
		const handlerDOM = findDOMNode(this.props.handler.current) as Element

		handlerDOM.removeEventListener('mouseleave', this.HandleMouseLeave)

		if (!this.IsDropdownParent(event.relatedTarget as Element)) {
			this.ToggleVisibility(false)
			return
		}

		const dropdownDOM = findDOMNode(this.dropdownRef.current) as Element

		dropdownDOM.addEventListener('mouseleave', this.HandleDropdownMouseLeave)
	}

	public HandleDropdownMouseLeave = () => {
		const dropdownDOM = findDOMNode(this.dropdownRef.current) as Element

		dropdownDOM.removeEventListener('mouseleave', this.HandleDropdownMouseLeave)

		this.ToggleVisibility(false)
	}

	public HandleWindowScroll = () => {
		this.UpdateDropdownPosition()
	}

	public IsDropdownParent = (element: Element): boolean => {
		const dropdownDOM = findDOMNode(this.dropdownRef.current) as Element

		while (element instanceof Element) {
			if (element === dropdownDOM) {
				return true
			}

			// eslint-disable-next-line no-param-reassign
			element = element.parentNode as Element
		}

		return false
	}

	public ToggleVisibility = (visible?: boolean) => {

		if (visible) {
			window.addEventListener('scroll', this.HandleWindowScroll)
		} else {
			window.removeEventListener('scroll', this.HandleWindowScroll)
		}

		this.setState((prevState) => ({
			visible: visible === void 0 ? !prevState.visible : visible,
		}))
	}

	public UpdateDropdownPosition = () => {
		const handlerDOM = findDOMNode(this.props.handler.current) as Element
		const handlerRect = handlerDOM.getBoundingClientRect()

		this.setState({
			style: {
				top: handlerRect.top + handlerRect.height,
				...(this.props.position === 'left' ? {
					left: handlerRect.left,
				} : {
					right: document.body.offsetWidth - (handlerRect.left + handlerRect.width),
				}),
			},
		})
	}

	public render() {
		return (
			<Fragment>
				{this.state.visible && (
					<Root ref={ this.dropdownRef } style={ this.state.style }>
						{this.props.children}
					</Root>
				)}
			</Fragment>
		)
	}
}
