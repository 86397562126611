import React from 'react'

import { CookieType } from '@containers/new/CookiesPopup'
import CookieInfo from '@containers/new/CookiesPopup/partials/CookieInfo'

import { BreakLine } from '@containers/new/CookiesPopup/CookiePopup.Styled'

interface DetailsProps {
	selected: CookieType[],
	switchSelection: (section: CookieType, value: boolean) => void,
}

const Details = (props: DetailsProps) => {
	return (
		<>
			<p>Więcej informacji dotyczących plików Cookies oraz przetwarzania danych osobowych w plikach Cookies znajduje się
				w naszej <a href="/polityka-prywatnosci.html" title="Polityka prywatności">Polityce Prywatności</a> .</p>
			<div>
				<p>Wyrażam zgodę na:</p>
				<CookieInfo
					cookieType="required"
					isSelected={ true }
					switchSelection={ props.switchSelection }
				/>
				<BreakLine/>
				<CookieInfo
					cookieType="statistical"
					isSelected={ props.selected.indexOf('statistical') > -1 }
					switchSelection={ props.switchSelection }
				/>
				<BreakLine/>
				<CookieInfo
					cookieType="marketing"
					isSelected={ props.selected.indexOf('marketing') > -1 }
					switchSelection={ props.switchSelection }
				/>
			</div>
		</>
	)
}

export default Details
