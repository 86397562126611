import { renderCookiePopup } from '@containers/new/CookiesPopup'
import { LocalStorage } from '@sharedAssets/functions/storage'
if (!LocalStorage.Get('consentMode')) {
	renderCookiePopup()
}

document.querySelector('#render-cookie-popup-link')?.addEventListener('click', (event) => {
	event.preventDefault()

	if (!document.querySelector('#cookiesPopup')) {
		renderCookiePopup()
	}
})