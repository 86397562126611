/* eslint-disable */
import * as React from 'react'

import MDCTextField, { HelperText, Input } from '@material/react-text-field'

import { Icon } from '@components/React'
import { Autocomplete, AutocompleteLink, Root } from './TextField.Styled'

type Timeout = ReturnType<typeof setTimeout>

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  autocomplete?: (value: string) => Promise<string []>,
  className?: string,
  color?: 'light',
  disabled?: boolean,
  helperText?: any,
  inputClassName?: string,
  isValid?: boolean,
  label?: string,
  leadingIcon?: any,
  outlined?: boolean,
  required?: boolean,
  textarea?: boolean,
  trailingIcon?: any,
  validation?: string,
  value?: any,
}

interface TextFieldState {
  autocomplete: string[][],
  autocompleteCache: {[value: string]: string[][]},
  selectedAutocomplete: number,
  showAutocomplete: boolean,
}

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
	public inputRef: HTMLInputElement = null

	private autocompleteTimeout: Timeout = null

	constructor(props) {
		super(props)

		this.state = {
			autocomplete: [],
			autocompleteCache: {},
			selectedAutocomplete: -1,
			showAutocomplete: false,
		}
	}

	public render() {
		const inputProps = { ...this.props }
		const rootProps: TextFieldProps = {}
		const fieldProps: TextFieldProps = { className: '' }

		if (inputProps.autocomplete) {
			inputProps.autoComplete = 'off'

			delete inputProps.autocomplete
		}

		if (inputProps.className) {
			rootProps.className = inputProps.className

			delete inputProps.className
		}

		if (inputProps.color && inputProps.color === 'light') {
			fieldProps.className += ' mdc-text-field--light'
			delete inputProps.color
		}

		if (inputProps.disabled) {
			fieldProps.className += ' mdc-text-field--disabled'
		}

		if (inputProps.helperText) {
			fieldProps.helperText = <HelperText>{inputProps.helperText}</HelperText>
		}

		if (inputProps.inputClassName) {
			inputProps.className = inputProps.inputClassName
		}

		if (inputProps.label) {
			fieldProps.label = `${inputProps.label} `
		}

		if (inputProps.leadingIcon) {
			fieldProps.leadingIcon = <Icon type={ inputProps.leadingIcon } weight="light"/>
		}

		if (inputProps.outlined) {
			fieldProps.outlined = inputProps.outlined
		}

		if (inputProps.textarea) {
			fieldProps.textarea = inputProps.textarea
		}

		if (inputProps.trailingIcon) {
			fieldProps.trailingIcon = <Icon type={ inputProps.trailingIcon } weight="light"/>
		}

		if (inputProps.validation) {
			const { validation } = inputProps

			fieldProps.helperText = (
				<HelperText persistent validation>
					{Array.isArray(validation) ? inputProps.validation[0] : inputProps.validation}
				</HelperText>
			)

			fieldProps.className += ' mdc-text-field--invalid'
		}

		delete inputProps.helperText
		delete inputProps.inputClassName
		delete inputProps.label
		delete inputProps.leadingIcon
		delete inputProps.onChange
		delete inputProps.outlined
		delete inputProps.textarea
		delete inputProps.trailingIcon
		delete inputProps.validation

		return (
			<Root { ...rootProps }>
				<MDCTextField { ...fieldProps }>
					<Input { ...inputProps }
						onBlur={ () => setTimeout(() => this.setState({ showAutocomplete: false }), 500) }
						onChange={ this.handleInputChange }
						onFocus={ () => this.setState({ showAutocomplete: true }) }

						// @ts-ignore
						onKeyDown={ (event: KeyboardEvent) => this.handleInputKeyDown(event as any) }
						ref={ (ref) => { this.inputRef = ref ? ref.inputElement_.current : null } }
					/>
				</MDCTextField>
				{this.props.autocomplete && this.state.autocomplete.length > 0 && this.state.showAutocomplete && (
					<Autocomplete onMouseLeave={ () => this.setState({ selectedAutocomplete: -1 }) }>
						{this.state.autocomplete.map((autocomplete, index) => (
							<AutocompleteLink dangerouslySetInnerHTML={ { __html: autocomplete[1] } }
								key={ autocomplete[0] }
								onClick={ () => this.handleAutocompleteClick(autocomplete[0]) }
								onMouseEnter={ () => this.setState({ selectedAutocomplete: index }) }
								selected={ this.state.selectedAutocomplete === index }
							/>
						))}
					</Autocomplete>
				)}
			</Root>
		)
	}

	private getParts = (text: string) => text.split(',')

	private getActivePartIndex = (input = this.inputRef) => {
		const parts = this.getParts(input.value)
		const caretPosition = input.selectionEnd

		let lengthSum = 0

		for (let partIndex = 0; partIndex < parts.length; partIndex++) {
			lengthSum += parts[partIndex].length + 1 // +1 caused by comma

			if (caretPosition < lengthSum) {
				return partIndex
			}
		}

		return -1
	}

	private getActivePart = (input = this.inputRef) => {
		const parts = this.getParts(input.value)
		const partIndex = this.getActivePartIndex(input)

		if (partIndex >= 0) {
			return parts[partIndex]
		}

		return ''
	}

	private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { props } = this

		if (props.autocomplete) {
			clearTimeout(this.autocompleteTimeout)

			const part = this.getActivePart(event.target).replace(/^ *(.*) *$/, '$1')

			if (part.length >= 2) {
				if (this.state.autocompleteCache.hasOwnProperty(part)) {
					this.setState({ autocomplete: this.state.autocompleteCache[part], selectedAutocomplete: -1 })
				} else {
					this.autocompleteTimeout = setTimeout(async() => {
						const regex = new RegExp(`(${part})`, 'i')
						const autocomplete = (await props.autocomplete(part))
							.map((val) => [ val, val.replace(regex, '<b>$1</b>') ])

						this.setState((prevState) => ({
							autocomplete,
							autocompleteCache: {
								...prevState.autocompleteCache,
								[part]: autocomplete,
							},
							selectedAutocomplete: -1,
							showAutocomplete: true,
						}))
					}, 250) as undefined as Timeout
				}
			} else {
				this.setState({ autocomplete: [], showAutocomplete: true })
			}
		}

		if (props.onChange) {
			props.onChange(event)
		}
	}

	private handleInputKeyDown = (event: KeyboardEvent): void => {
		switch (event.key) {
		case 'ArrowDown':
			event.preventDefault()
			this.setState((prevState) => ({
				selectedAutocomplete: prevState.selectedAutocomplete < prevState.autocomplete.length - 1 ?
					prevState.selectedAutocomplete + 1 :
					prevState.selectedAutocomplete,
			}))
			break
		case 'ArrowUp':
			event.preventDefault()
			this.setState((prevState) => ({
				selectedAutocomplete: prevState.selectedAutocomplete > 0 ?
					prevState.selectedAutocomplete - 1 :
					prevState.selectedAutocomplete,
			}))
			break
		case 'Enter':
			if (this.state.selectedAutocomplete >= 0) {
				event.preventDefault()
				this.handleAutocompleteClick(this.state.autocomplete[this.state.selectedAutocomplete][0])
				this.setState({ selectedAutocomplete: -1 })
			}
			break
		}
	}

	private handleAutocompleteClick = (value: string) => {
		this.setState({ autocomplete: [], showAutocomplete: false })

		if (this.props.onChange) {
			const parts = this.getParts(this.inputRef.value)
			const partIndex = this.getActivePartIndex(this.inputRef)

			if (partIndex > -1) {
				parts[partIndex] = ` ${value}`
			}

			this.inputRef.value = `${parts.join(',').replace(/, ?$/, '')}, `
			this.props.onChange({ target: this.inputRef } as any)
			this.inputRef.focus()
		}
	}

}

export default TextField
