import { FlexDirectionType } from '@sharedAssets/interfaces/styles'

export const FlexDirection = (direction: FlexDirectionType) => ({
	flexDirection: direction,
	msFlexDirection: direction,
})

export const DisplayFlex = (direction: FlexDirectionType = 'row') => ({
	display: 'flex',
	msDisplay: 'flex',
	...FlexDirection(direction),
})

export const Flex = (flex: number, flexBasis: number | string = 'auto') => ({
	flex,
	flexBasis,
})
