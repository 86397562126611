import React from 'react'


const Informations = () => {
	return (
		<>
			<p>Wykorzystujemy Pliki Cookies w celu zapewnienia <strong>prawidłowego funkcjonowania</strong> Serwisu. Stosujemy Pliki Cookies
				w celach statystycznych i marketingowych, w szczególności w zakresie dopasowania treści reklamowych do Twoich
				preferencji.
			</p>
			<p>Każda wyrażona zgoda może być przez Ciebie <strong>wycofana w dowolnym momencie</strong>. Więcej informacji
				dotyczących plików Cookies oraz przetwarzania danych osobowych w plikach Cookies znajduje się w naszej <a
				href="/polityka-prywatnosci.html" title="Polityka prywatności">Polityce Prywatności</a>.</p>
			<p>Administratorem Serwisu i Twoich danych osobowych jest „Praca.pl” spółka z ograniczoną
				odpowiedzialnością z siedzibą w Warszawie, ul. Bolesława Prusa 2, 00-493 Warszawa, KRS 0000290935, NIP
				7010095135, REGON: 141169379</p>
		</>
	)
}

export default Informations
