import { HTMLProps } from 'react'
import Emotion from 'react-emotion'

import MediaQuery from '@styles/MediaQueries'

interface ColumnProps extends HTMLProps<HTMLDivElement> {
    noPadding?: boolean,
    small?: number,
    xmobile?: number,
    medium?: number,
    large?: number,
    xlarge?: number,
    xxlarge?: number,
}

export const Column = Emotion('div')((props: ColumnProps) => ({
	paddingLeft: props.noPadding ? null : 15,
	paddingRight: props.noPadding ? null : 15,

	...(props.small ? {
		[MediaQuery.Small]: {
			width: `${ props.small / 12 * 100 }%`,
		},
	} : {}),

	...(props.xmobile ? {
		[MediaQuery.Xmobile]: {
			width: `${ props.xmobile / 12 * 100 }%`,
		},
	} : {}),

	...(props.medium ? {
		[MediaQuery.Medium]: {
			width: `${ props.medium / 12 * 100 }%`,
		},
	} : {}),

	...(props.large ? {
		[MediaQuery.Large]: {
			width: `${ props.large / 12 * 100 }%`,
		},
	} : {}),

	...(props.xlarge ? {
		[MediaQuery.Xlarge]: {
			width: `${ props.xlarge / 12 * 100 }%`,
		},
	} : {}),

	...(props.xxlarge ? {
		[MediaQuery.Xxlarge]: {
			width: `${ props.xxlarge / 12 * 100 }%`,
		},
	} : {}),
}))

Column.defaultProps = {
	small: 12,
}

export default Column
