import { Blue, White } from '@styles/Color'
import Styled from 'react-emotion'

interface AutocompleteLinkProps {
  selected: boolean,
}

export const Autocomplete = Styled('div')({
  background: White,
  border: `solid 1px ${Blue[800]}`,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 56,
  zIndex: 1000,
})

export const AutocompleteLink = Styled('a')<AutocompleteLinkProps>((props) => ({
  background: props.selected ? Blue[50] : null,
  display: 'block',
  padding: '3px 6px',
}))

export const Root = Styled('div')({
  position: 'relative',
})
