/* eslint-disable */
import * as React from 'react'

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  containerClassName?: string,
  indeterminate?: boolean,
  label: string,
  secondary?: boolean,
  type?: 'checkbox',
}

interface CheckboxState {
  id: string,
}

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
	private checkbox: HTMLInputElement

	constructor(props: CheckboxProps) {
		super(props)

		this.state = {
			id: `mdc-chkbx-${Math.round(Math.random() * 99999)}`,
		}
	}

	public toggleChecked = () => {
		if (this.checkbox.disabled) {
			return
		}

		this.checkbox.indeterminate = false
		this.checkbox.checked = !this.checkbox.checked
	}

	public componentDidMount() {
		this.updateCheckboxIndeterminate()
	}

	public componentDidUpdate() {
		this.updateCheckboxIndeterminate()
	}

	public render() {
		const safeProps = { ...this.props }
		const { id } = this.state
		const containerProps = { className: 'mdc-checkbox' }
		const { label = '' } = safeProps
		const containerClasses = ['mdc-form-field']

		safeProps.type = 'checkbox'

		if (safeProps.className) {
			safeProps.className = `mdc-checkbox__native-control ${safeProps.className}`
		} else {
			safeProps.className = 'mdc-checkbox__native-control'
		}

		if (safeProps.secondary) {
			containerProps.className += ' mdc-checkbox--secondary'
			delete safeProps.secondary
		}

		if (safeProps.disabled) {
			containerProps.className += ' mdc-checkbox--disabled'
		}

		if (safeProps.indeterminate !== undefined) {
			safeProps.indeterminate = String(safeProps.indeterminate) as any
		}

		if (safeProps.containerClassName) {
			containerClasses.push(safeProps.containerClassName)
			delete safeProps.containerClassName
		}

		return (
			<div className={ containerClasses.join(' ') }>
				<div { ...containerProps }>
					<input { ...safeProps } ref={ (element) => { this.checkbox = element } } id={ id }/>
					<div className="mdc-checkbox__background">
						<svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
							<path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
						</svg>
						<div className="mdc-checkbox__mixedmark"/>
					</div>
				</div>
				<label htmlFor={ id }>{ label }</label>
			</div>
		)
	}

	private updateCheckboxIndeterminate = () => {
		if (this.props.indeterminate) {
			this.checkbox.checked = false
			this.checkbox.indeterminate = true
		} else {
			this.checkbox.indeterminate = false
		}
	}
}

export default Checkbox
