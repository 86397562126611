interface StorageRawValue<T = any> {
  time: Date,
  value: T,
}

const TimeRegexp = /^\d{10,15}\|/

const getValue = (value: string) => {
	const stringifiedValue = decodeURIComponent(value).replace(TimeRegexp, '')

	try {
		return JSON.parse(stringifiedValue)
	} catch (error) {
		return stringifiedValue
	}
}
const getTime = (value: string) => new Date(TimeRegexp.test(value) ? parseInt(value.split('|')[0], 10) : 0)

const stringifyValue = (value: any) => encodeURIComponent(typeof value === 'string' ? value : JSON.stringify(value))

interface Cookies<T = any> {
  [ name: string ]: T,
}

interface Cookie {
  Get<T = any>(name: string, raw: true): StorageRawValue<T>,
  Get<T = any>(name: string, raw?: boolean): T,

  GetAll(raw: true): Cookies<StorageRawValue>,
  GetAll(raw?: boolean): Cookies,

  Remove(name: string): void,

  Set(name: string, value: any, daysToExpire?: number): void,
}

export const Cookie: Cookie = {
	Get: (name, raw = false) => {
		const value = Cookie.GetAll(raw)[name]

		return raw && !value ? {
			time: new Date(0),
			value: void 0,
		} : value
	},
	GetAll: (raw = false) => {
		const cookies = {}

		document.cookie
			.split('; ')
			.forEach((cookie: string) => {
				const indexOfValue = cookie.indexOf('=')
				const cookieRightSide = cookie.slice(indexOfValue + 1)

				cookies[cookie.slice(0, indexOfValue)] = raw ? ({
					time: getTime(cookieRightSide),
					value: getValue(cookieRightSide),
				}) : getValue(cookieRightSide)
			})

		return cookies
	},
	Remove: (name) => Cookie.Set(name, 'null', -1),
	Set: (name, value, daysToExpire = 0) => {
		const expires = new Date()

		if (daysToExpire !== 0) {
			expires.setDate(expires.getDate() + daysToExpire)
		}

		document.cookie = `${name}=${Date.now()}|${stringifyValue(value)}${daysToExpire !== 0 ? `; expires=${expires}` : ''}; path=/; domain=.${location.host}`
	},
}

interface LocalStorage {
  Get<T = any>(name: string, raw: true): { time: Date, value: T },
  Get<T = any>(name: string, raw?: false): T,

  Remove(name: string): void,

  Set(name: string, value: any): void,
}

export const LocalStorage: LocalStorage = {
	Get: (name, raw = false) => {
		let itemValue = ''

		try {
			itemValue = localStorage.getItem(name)
		} catch (error) {
			if ((error.message as string).toLowerCase().indexOf('access is denied') > -1) {
				console.error('Brak uprawnień do odczytu danych z localStorage')
			}
			console.error(error)
		}

		if (itemValue) {
			return raw ? ({
				time: getTime(itemValue),
				value: getValue(itemValue),
			}) : getValue(itemValue)
		}
		return raw ? {
			time: new Date(0),
			value: void 0,
		} : void 0
	},
	Remove: (name) => localStorage.removeItem(name),
	Set: (name, value) => localStorage.setItem(name, `${Date.now()}|${stringifyValue(value)}`),
}

interface SessionStorage {
  Get<T = any>(name: string, raw: true): { time: Date, value: T },
  Get<T = any>(name: string, raw?: false): T,

  Remove(name: string): void,

  Set(name: string, value: any): void,
}

export const SessionStorage: SessionStorage = {
	Get: (name, raw = false) => {
		const itemValue = sessionStorage.getItem(name)

		if (itemValue) {
			return raw ? ({
				time: getTime(itemValue),
				value: getValue(itemValue),
			}) : getValue(itemValue)
		}
		return raw ? {
			time: new Date(0),
			value: void 0,
		} : void 0
	},
	Remove: (name) => sessionStorage.removeItem(name),
	Set: (name, value) => sessionStorage.setItem(name, `${Date.now()}|${stringifyValue(value)}`),
}

export default {
	Cookie,
	LocalStorage,
	SessionStorage,
}
