import Color, { Alpha } from '@styles/Color'
import { DisplayGrid, GridColumn, GridRow, GridTemplateColumns, GridTemplateRows, JustifySelf } from '@styles/Grid'
import MediaQuery from '@styles/MediaQueries'
import { MediaQueryObject, MediaQueryType } from '@sharedAssets/interfaces/styles'
import Emotion, { css } from 'react-emotion'

interface GridTemplate {
  columns?: string,
  rows?: string,
}

declare global {
  type TableGridTemplateColumns = string | MediaQueryObject<string>

  type TableRowGridTemplate = GridTemplate | MediaQueryObject<GridTemplate>
}

interface TableProps {
  columnGap: number,
  grid: TableGridTemplateColumns,
  rows: number,
}

interface TableRowProps {
  grid: TableRowGridTemplate,
  gridRow: number,
  hasChildren?: boolean,
  isChild?: boolean,
}

interface TableCellProps {
  allowOverflow?: boolean,
  column?: number[] | MediaQueryObject<number[]>,
  row?: number[] | MediaQueryObject<number[]>,
  showFor?: MediaQueryType,
}

interface TableRowChildrenTogglerContainerProps {
  column?: number | MediaQueryObject<number>,
}

export const Table = Emotion('div')<TableProps>`
  ${DisplayGrid()}
  ${({ rows }) => GridTemplateRows('auto '.repeat(rows))}
  position: relative;
  column-gap: ${({ columnGap }) => columnGap > 0 ? columnGap + 'px' : '0'};

  ${({ grid = {} }) =>
    typeof grid === 'string' ?
      GridTemplateColumns(grid) :
      Object.keys(grid).map((breakpoint) => css`
        ${MediaQuery[breakpoint]} {
          ${GridTemplateColumns(grid[breakpoint])}
        }
      `)
}
`

export const TableHead = Emotion('div')`
  align-items: center;
  background: ${Alpha(Color.White, 0.5)};
  box-shadow: 0 8px 8px 0 ${Alpha(Color.Black, 0.1)};
  color: ${Color.Gray[600]};
  ${DisplayGrid()}
  ${GridColumn(1, -1, true)}
  ${GridRow(1)}
  ${GridTemplateColumns('inherit')}
  column-gap: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 60px;
  line-height: 18px;
  margin: 0 0 20px 0;
  padding: 10px;

  ${MediaQuery.Medium} {
    padding: 10px 20px;
  }
`

export const TableRow = Emotion('div')<TableRowProps>`
  background: ${({ isChild }) => isChild ? Alpha(Color.Black, 0.05) : Color.White};
  box-shadow: ${({ isChild }) => isChild ? 'transparent' : `0 8px 8px 0 ${Alpha(Color.Black, 0.1)}`};
  color: ${Color.Gray[800]};
  column-gap: inherit;
  ${DisplayGrid()}
  font-size: 14px;
  ${GridColumn(1, -1, true)}
  ${({ gridRow }) => GridRow(gridRow)}
  margin: ${({ isChild }) => isChild ? '0' : '0 0 20px 0'};
  padding: 10px;
  transition: all 0.1s ease;

  ${({ isChild }) => isChild ? `
    &:not(:last-child) {
      border-bottom: solid 2px ${Alpha(Color.Black, 0.1)};
    }
  ` : `
    &:hover {
      background: ${Alpha(Color.LightBlue[100], 0.1)};
      box-shadow: 0 0 17px 0 ${Alpha(Color.Black, 0.5)};
    }
  `}

  ${({ grid = {}, hasChildren }) =>
    grid.hasOwnProperty('columns') || grid.hasOwnProperty('rows') ?
      {
        ...((grid as GridTemplate).columns ? GridTemplateColumns((grid as GridTemplate).columns) : {}),
        ...((grid as GridTemplate).rows ? GridTemplateRows((grid as GridTemplate).rows + hasChildren ? ' auto' : '') : {}),
      } :
      Object.keys(grid).map((breakpoint) => css`
      ${MediaQuery[breakpoint]} {
        ${{
    ...(grid[breakpoint].columns ? GridTemplateColumns(grid[breakpoint].columns) : {}),
    ...(grid[breakpoint].rows ? GridTemplateRows(grid[breakpoint].rows + hasChildren ? ' auto' : '') : {}),
  }}
      }
    `)
}

  ${MediaQuery.Medium} {
    padding: 10px 20px;
  }
`

export const TableCell = Emotion('div')<TableCellProps>`
  ${({ showFor = null }) => showFor ? css`
    display: none;

    ${MediaQuery[showFor]} {
      display: block;
    }
  ` : null}

  ${({ column }) => !column ? null : Array.isArray(column) ?
    GridColumn.apply(null, column) :
    Object.keys(column).map((breakpoint) => css`
      ${MediaQuery[breakpoint]} {
        ${GridColumn.apply(null, column[breakpoint])}
      }
    `)
}

  ${({ row }) => !row ? null : Array.isArray(row) ?
    GridRow.apply(null, row) :
    Object.keys(row).map((breakpoint) => css`
      ${MediaQuery[breakpoint]} {
        ${GridRow.apply(null, row[breakpoint])}
      }
    `)
}

  overflow: ${({ allowOverflow }) => allowOverflow ? 'visible' : 'hidden'};
  padding: 2px;
`

export const TableCellHeader = Emotion('span')`
  display: inline-block;
  text-align: center;
`

export const TableSortButton = Emotion('button')`
  background: transparent;
  border: none;
  color: ${Color.Blue[800]};
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  outline: none;
  padding: 5px;
  vertical-align: middle;
`

export const TableRowChildrenContainer = Emotion('div')`
  ${DisplayGrid()}
  ${GridTemplateColumns('inherit')}
  ${GridColumn(1, -1, true)}
  ${GridRow(99)}
  column-gap: inherit;
  margin: 0 -10px -10px -10px;

  ${MediaQuery.Medium} {
    margin: 0 -20px -10px -20px;
  }
`

export const TableRowChildrenToggler = Emotion('button')`
  background: ${Alpha(Color.Black, 0.05)};
  color: ${Color.Blue[800]};
  cursor: pointer;
  margin-left: 10px;
  outline: none;
  padding: 10px 15px;

  ${MediaQuery.Medium} {
    margin-left: 20px;
  }
`

export const TableRowChildrenTogglerContainer = Emotion('div')<TableRowChildrenTogglerContainerProps>`
  ${GridRow(1)}
  ${JustifySelf('start')}

  ${({ column }) => !column ? null : typeof column === 'number' ?
    GridColumn(column) :
    Object.keys(column).map((breakpoint) => css`
        ${MediaQuery[breakpoint]} {
          ${GridColumn(column[breakpoint])}
        }
      `)
}
`

export const IconChildrenExpand = css`
  font-size: 20px;
  margin-left: 5px;
  vertical-align: middle;
`
