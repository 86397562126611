export const DisplayGrid = () => ({
	display: [ '-ms-grid', 'grid' ],
})

export const GridTemplateColumns = (template: string) => ({
	MsGridColumns: template,
	gridTemplateColumns: template,
})

export const GridTemplateRows = (template: string) => ({
	MsGridRows: template,
	gridTemplateRows: template,
})

export const GridColumn = (from: number, to?: number, notAsSpan = false) => ({
	MsGridColumn: `${from};`,
	MsGridColumnSpan: notAsSpan ? `${to < 0 ? 999 : (to === void 0 ? from : to) - from + 1};` : `${to === void 0 ? 1 : to};`,
	gridColumn: notAsSpan ? `${from} / ${to === void 0 ? from : to}` : `${from} / span ${to === void 0 ? 1 : to}`,
})

export const GridRow = (start: number | string, span = 1) => ({
	MsGridRow: `${start};`,
	MsGridRowSpan: `${span};`,
	gridRow: `${start} / span ${span}`,
})

export const JustifySelf = (justify: 'start' | 'end' | 'center' | 'stretch', columnInsteadOfRow = false) => {
	const style: { [ key: string ]: any } = {
		justifySelf: justify,
	}

	if (columnInsteadOfRow) {
		style.MsGridColumnAlign = justify
	} else {
		style.MsGridRowAlign = justify
	}

	return style
}

export const AlignSelf = (align: 'start' | 'end' | 'center' | 'stretch', rowInsteadOfColumn = false) => {
	const style: { [ key: string ]: any } = {
		alignSelf: align,
	}

	if (rowInsteadOfColumn) {
		style.MsGridRowAlign = align
	} else {
		style.MsGridColumnAlign = align
	}

	return style
}

export default {
	AlignSelf,
	DisplayGrid,
	GridColumn,
	GridRow,
	GridTemplateColumns,
	GridTemplateRows,
	JustifySelf,
}
