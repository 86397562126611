import Color from '@styles/Color'
import Emotion, { css } from 'react-emotion'

interface DropdownLinkProps {
  withIcon?: boolean,
}

export const Root = Emotion('div')`
  background: ${Color.White};
  border: solid 1px ${Color.Blue[800]};
  display: block;
  position: fixed;
  text-align: left;
  white-space: nowrap;
`

export const DropdownLink = Emotion('a')<DropdownLinkProps>`
  display: block;
  padding: 3px 5px;

  ${({ withIcon }) => withIcon ? css`
    > span:first-child {
      margin-right: 5px;
    }
  ` : null}
`

export const DropdownItem = Emotion('div')`
  display: block;
  padding: 3px 5px;
`
