import Styled, { css } from 'react-emotion'

import { Black, Blue, Gray, Orange, Red, White } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

interface RootProps {
  bold?: boolean,
  closable?: boolean,
  color?: 'primary' | 'secondary' | 'white' | 'red' | 'black',
  inline?: boolean,
}

const getColor = (color?) => {
  switch (color) {
    case 'primary':
      return Blue[800]
    case 'secondary':
      return Orange[500]
    case 'black':
      return Black
    case 'red':
      return Red[500]
    case 'gray':
      return Gray[200]
    default:
      return White
  }
}

const getContrastColor = (color?) => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'black':
    case 'red':
      return White
    case 'gray':
      return Gray[800]
    default:
      return Black
  }
}

export const ChipsIconCloseClass = css({
  color: Red[500],
  cursor: 'pointer',
  fontSize: 30,
  lineHeight: '30px',
  position: 'absolute',
  right: 15,
  top: '50%',
  transform: 'translateY(-50%)',

  [MediaQuery.Large]: {
    fontSize: 18,
    lineHeight: '18px',
    right: 12,
  },
})

export const Root = Styled('div')<RootProps>(({ bold, closable, color, inline }) => ({
  background: getColor(color),
  borderRadius: 50,
  color: getContrastColor(color),
  display: inline ? 'inline-block' : 'block',
  fontSize: 12,
  fontWeight: bold ? 700 : 400,
  lineHeight: '20px',
  margin: '5px 10px',
  maxWidth: '100%',
  overflow: 'hidden',
  padding: closable ? '10px 50px 10px 15px' : '5px 10px',
  position: 'relative',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'fit-content',

  [MediaQuery.Large]: {
    margin: '3px 7px',
    padding: closable ? '5px 30px 5px 10px' : '5px 10px',
  },
}))
