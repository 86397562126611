/* eslint-disable sort-keys */
import { MediaQueryObject, MediaQueryType } from '@sharedAssets/interfaces/styles'

export const breakpoints: MediaQueryObject<number> = {
	Small: 0,
	Xmobile: 480,
	Medium: 767,
	Large: 1024,
	Xlarge: 1200,
	Xxlarge: 1440,
}

export const getBreakpoint = (): MediaQueryType => {
	let largestAccepted: MediaQueryType = 'Small'

	for (const key of Object.keys(breakpoints)) {
		if (window.innerWidth >= breakpoints[key] && breakpoints[key] > breakpoints[largestAccepted]) {
			largestAccepted = key as MediaQueryType
		}
	}

	return largestAccepted
}

export const isBreakpointValid = (breakpoint: MediaQueryType): boolean =>
	breakpoints[getBreakpoint()] >= breakpoints[breakpoint]

export const MediaQuery = ((): MediaQueryObject<string> => {
	const breakPoints: MediaQueryObject<number | string> = { ...breakpoints }

	for (const key of Object.keys(breakPoints)) {
		breakPoints[key] = `@media all and (min-width: ${breakPoints[key]}px)`
	}

	return breakPoints as MediaQueryObject<string>
})()

export default MediaQuery
