/* eslint-disable */
import React from 'react'
import Emotion from 'react-emotion'

const Root: any = Emotion('h1')({
	fontSize: 20,
	fontWeight: 300,
	margin: '0 0 .5em 0',
	paddingLeft: 20,
	textTransform: 'uppercase',
})

export default function Title(props) {
	return <Root { ...props }/>
}
