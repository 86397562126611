import React from 'react'

import { Button } from '@mui/material'

import { cookieButton } from '@containers/new/CookiesPopup/CookiePopup.Styled'

interface ButtonsProps {
	acceptAllClick: () => void,
	acceptSelectedClick: () => void,
	isSelected: boolean,
	shownDetails: boolean,
	showDetails: () => void,
}

const Buttons = (props: ButtonsProps) => {
	return (
		<>
			<Button
				onClick={ props.acceptAllClick }
				variant="contained"
				className={ cookieButton }
			>Akceptuj wszystkie</Button>
			{ props.shownDetails ?
				<Button onClick={ props.acceptSelectedClick } variant="outlined" className={ cookieButton }>
					{ props.isSelected ? 'Akceptuj wybór' : 'Odrzuć wszystkie' }
				</Button> :
				<Button onClick={ props.showDetails } variant="outlined" className={ cookieButton }>Akceptuj wybrane</Button>
			}
		</>
	)
}

export default Buttons
