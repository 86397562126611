import Style, { css } from 'react-emotion'

import { Alpha, Black, Gray, White } from '@styles/Color'
import { MediaQuery } from '@styles/MediaQueries'

export const sectionIcon = css({
	transform: 'rotate(0)',
	transition: 'transform ease .3s',
})

export const sectionOpenIcon = css({
	transform: 'rotate(180deg)',
})

export const cookieButton = css({
	width: 240,
	marginLeft: 'auto',
	marginRight: 'auto',
	gridRow: 3,

	'&:first-of-type': {
		gridRow: 2,
	},

	[MediaQuery.Medium]: {
		gridRow: 2,
		gridColumn: 3,

		'&:first-of-type': {
			gridRow: 2,
			gridColumn: 2,
		},
	},

	[MediaQuery.Large]: {
		gridRow: 3,
		gridColumn: 1,

		'&:first-of-type': {
			gridRow: 2,
			gridColumn: 1,
		},
	},
})

export const Root = Style('div')({
	display: 'block',
	position: 'fixed',
	width: '100vw',
	height: '100vh',
	top: 0,
	zIndex: 999999999,

	'&::after': {
		display: 'block',
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: Alpha(Black, 0.5),
		content: '""',
	}
})

export const ContentBlock = Style('div')<{ showDetails: boolean }>(({ showDetails }) => ({
	display: 'grid',
	position: 'fixed',
	width: '100vw',
	height: showDetails ? '70vh' : '40vh',
	bottom: 0,
	zIndex: 9999999991,
	borderRadius: '25px 25px 0 0',
	backgroundColor: White,
	gridTemplateRows: '1fr auto',
	padding: '15px 0',

	[MediaQuery.Medium]: {
		height: '50vh',
	},

	[MediaQuery.Large]: {
		height: showDetails ? '430px' : '380px',
		gridTemplateColumns: '1fr auto',
		gridColumnGap: 40,
		padding: '25px 0',
	},

	[MediaQuery.Xlarge]: {
		gridTemplateColumns: '1fr 690px 290px 1fr',
	},
}))

export const MainBlock = Style('div')({
	gridRow: 1,
	maxWidth: '100%',
	overflowX: 'hidden',
	overflowY: 'auto',
	padding: '0 15px 15px',
	color: Gray[800],

	[MediaQuery.Large]: {
		gridColumn: 1,
	},

	[MediaQuery.Xlarge]: {
		gridColumn: 2,
	},
})

export const Title = Style('div')({
	fontSize: 18,
	fontWeight: 700,
	marginBottom: 25,

	[MediaQuery.Large]: {
		fontSize: 24,
	},
})

export const DetailsBlock = Style('div')({
	fontSize: 12,

	[MediaQuery.Large]: {
		fontSize: 14,
	},
})

export const ButtonsBlock = Style('div')({
	display: 'grid',
	gridGap: 10,
	gridTemplateRows: 'auto 1fr 1fr',
	justifyItems: 'center',

	'&::before': {
		content: '""',
		width: '100%',
		display: 'block',
		height: 4,
		background: `linear-gradient(to top, ${ Alpha(Black, 0.2) }, transparent)`,
	},

	[MediaQuery.Medium]: {
		gridTemplateColumns: '1fr auto auto 1fr',
		gridTemplateRows: 'auto 1fr',

		'&::before': {
			gridColumn: '1/span 4',
		},
	},

	[MediaQuery.Large]: {
		gridColumn: 2,
		gridGap: 25,
		gridTemplateRows: '1fr auto auto 1fr',
		gridTemplateColumns: '1fr',
		padding: 25,

		'&::before': {
			display: 'none',
		},
	},

	[MediaQuery.Xlarge]: {
		gridColumn: 3,
	},
})

export const BreakLine = Style('div')({
	backgroundColor: Alpha(Black, 0.2),
	height: 1,
	margin: '5px -15px',
	width: '100vw',

	[MediaQuery.Large]: {
		margin: '5px 0',
		width: '100%',
	},
})

export const CookieItem = Style('div')({
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	gridTemplateRows: '1fr auto',
	margin: '0 -15px',
	padding: 10,

	[MediaQuery.Large]: {
		margin: 0,
	},
})

export const ToggleButton = Style('button')({
	minWidth: 38,
	cursor: 'pointer',
})

export const CookieDescription = Style('p')({
	marginBottom: 0,
	marginTop: 15,
	paddingBottom: 0,
})

export const SwitchLabel = Style('span')({
	color: Gray[800] + ' !important',
	fontSize: 14,

	[MediaQuery.Medium]: {
		fontSize: 16,
	},
})
