/* eslint-disable */
import { Icon } from '@components/React'
import * as React from 'react'

import MDCButton from '@material/react-button'

interface ButtonProps {
  block?: boolean,
  children?: any,
  className?: string,
  color?: 'secondary' | 'black' | 'red' | 'white' | 'gray' | 'orange',
  dense?: boolean,
  disabled?: boolean,
  href?: string,
  iconData?: {
    type: string,
    weight: 'light' | 'solid' | 'regular' | 'brand'
  }
  outlined?: boolean,
  raised?: boolean,
  red?: boolean,
  rounded?: boolean,
  secondary?: boolean,
  size?: 'small' | 'medium' | 'large',
  unelevated?: boolean,
  view?: 'text' | 'raised' | 'unelevated' | 'outlined',
  white?: boolean,
  [key: string]: any,
}

export function Button(props: ButtonProps) {
	const safeProps = { ...props }
	let fillType = 'text'

	let { className = '' } = safeProps

	if (safeProps.block) {
		className += ' mdc-button--block'
	}

	if (safeProps.dense) {
		className += ' mdc-button--dense'
	}

	if (safeProps.rounded) {
		className += ' mdc-button--rounded'
	}

	if (safeProps.size && safeProps.size !== 'medium') {
		className += ` mdc-button--${safeProps.size}`
	}

	if (safeProps.view) {
		safeProps.outlined = safeProps.view === 'outlined'
		safeProps.raised = safeProps.view === 'raised'
		safeProps.unelevated = safeProps.view === 'unelevated'

		fillType = safeProps.view === 'raised' || safeProps.view === 'unelevated' ? 'filled' : safeProps.view
	} else if (safeProps.raised || safeProps.unelevated) {
		fillType = 'filled'
	} else if (safeProps.outlined) {
		fillType = 'outlined'
	} else {
		fillType = 'text'
	}

	if (safeProps.color) {
		className += ` ${safeProps.color}-${fillType}-button`
	} else if (safeProps.secondary && !safeProps.red) {
		className += ` secondary-${fillType}-button`
	} else if (safeProps.red) {
		className += ` red-${fillType}-button`
	} else if (safeProps.white) {
		className += ` white-${fillType}-button`
	}

	delete safeProps.block
	delete safeProps.color
	delete safeProps.dense
	delete safeProps.red
	delete safeProps.rounded
	delete safeProps.secondary
	delete safeProps.size
	delete safeProps.white
	delete safeProps.view

	safeProps.className = className

	if (safeProps.iconData) {
		safeProps.icon = <Icon type={ props.iconData.type } weight={ props.iconData.weight }/>
		delete safeProps.iconData
	}

	return <MDCButton { ...safeProps }>{ safeProps.children }</MDCButton>
}

export default Button
