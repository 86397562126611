import Color from '@styles/Color'
import Emotion from 'react-emotion'

interface LimitButtonProps {
  active?: boolean
}

interface ButtonProps {
  active?: boolean,
}

export const Button = Emotion('button')<ButtonProps>`
  border: solid 1px ${Color.Gray[300]};
  display: inline-block;
  font-size: 12px;
  margin: 0 3px;
  outline: none;
  padding: 5px 8px;

  ${({ active }) => active ? `
    background: ${Color.Blue[800]};
    color: ${Color.White};
  ` : `
    background: transparent;
    color: ${Color.Black};
    cursor: pointer;
  `}
`

export const Container = Emotion('div')`
  margin: 10px auto 20px auto;
  text-align: center;
`

export const LimitBlock = Emotion('div')`
  display: inline-block;
  margin-right: 10px;
`

export const LimitButton = Emotion('button')<LimitButtonProps>`
  background: transparent;
  border: none;
  display: inline;
  font-size: inherit;
  margin: 0 0 0 3px;
  outline: none;

  ${({ active }) => active ? `
    color: ${Color.Black};
  ` : `
    color: ${Color.Blue[800]};
    cursor: pointer;
    text-decoration: underline;
  `}
`

export const LimitContainer = Emotion('div')`
  font-size: 10px;
  margin: 0 0 20px 0;
`

export const Root = Emotion('div')`
  color: ${Color.Black};
`
