import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Buttons from '@containers/new/CookiesPopup/partials/Buttons'
import Details from '@containers/new/CookiesPopup/partials/Details'
import Informations from '@containers/new/CookiesPopup/partials/Informations'
import { ThemeProvider } from '@mui/material/styles'
import { mainTheme } from '@sharedComponents/React/pracaMuiTheme'

import {
	ButtonsBlock,
	ContentBlock,
	DetailsBlock,
	MainBlock,
	Root,
	Title,
} from '@containers/new/CookiesPopup/CookiePopup.Styled'

declare type ConsentValue = 'granted' | 'denied'

interface GtagConsentModeData {
	'ad_storage': ConsentValue,
	'ad_user_data': ConsentValue,
	'ad_personalization': ConsentValue,
	'analytics_storage': ConsentValue,
	'personalization_storage': ConsentValue,
	'functionality_storage': ConsentValue,
	'security_storage': ConsentValue,
}

/**
 * PurgeCSS
 * <div class="fa-chevron-down"></div>
 */

export declare type CookieType = 'statistical' | 'marketing' | 'required'

interface CookiesPopupState {
	selected: CookieType[],
	showDetails: boolean,
}

const SELECTABLE_COOKIES: CookieType[] = [ 'statistical', 'marketing' ]

export default class CookiesPopup extends Component<Record<string, never>, CookiesPopupState> {
	constructor(props) {
		super(props)

		this.state = {
			selected: ['required'],
			showDetails: false,
		}
	}

	public componentDidMount() {
		const dataFromLocalStorage: GtagConsentModeData = JSON.parse(localStorage.getItem('consentMode'))

		if (dataFromLocalStorage) {
			const selectedItems: CookieType[] = ['required']

			if (dataFromLocalStorage.ad_storage === 'granted') {
				selectedItems.push('marketing')
			}
			if (dataFromLocalStorage.analytics_storage === 'granted') {
				selectedItems.push('statistical')
			}

			this.setState({ selected: selectedItems })
		}
	}

	public saveToStorage = (selectAll?: boolean) => {
		const selectedCookies = []
		const selectedFromState = this.state.selected

		const gtagConsentMode = {
			'ad_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
			'analytics_storage': 'denied',
			'personalization_storage': 'denied',
			'functionality_storage': 'denied',
			'security_storage': 'denied',
		}

		if (selectAll) {
			selectedCookies.push('required')
			Object.assign(selectedCookies, SELECTABLE_COOKIES)
		} else {
			Object.assign(selectedCookies, selectedFromState)
		}

		if (selectedCookies.length > 1) {
			gtagConsentMode['ad_storage'] = selectedCookies.indexOf('marketing') > -1 ? 'granted' : 'denied'
			gtagConsentMode['ad_user_data'] = selectedCookies.indexOf('marketing') > -1 ? 'granted' : 'denied'
			gtagConsentMode['ad_personalization'] = selectedCookies.indexOf('marketing') > -1 ? 'granted' : 'denied'
			gtagConsentMode['analytics_storage'] = selectedCookies.indexOf('statistical') > -1 ? 'granted' : 'denied'

		}
		localStorage.setItem('consentMode', JSON.stringify(gtagConsentMode))
		window.gtag('consent', 'update', gtagConsentMode)

		const rootElement = document.getElementById('cookiesPopup')

		ReactDOM.unmountComponentAtNode(rootElement)
		rootElement.remove()
	}

	public selectCookieType = (section: CookieType, value: boolean) => {
		this.setState((prevState) => {
			const newSelected: CookieType[] = Object.assign([], prevState.selected)

			if (value) {
				newSelected.push(section)
			} else {
				newSelected.splice(newSelected.indexOf(section), 1)
			}

			return ({
				...prevState,
				selected: newSelected,
			})
		})
	}

	public render() {
		return (
			<Root>
				<ThemeProvider theme={ mainTheme }>
					<ContentBlock showDetails={ this.state.showDetails }>
						<MainBlock>
							<Title>{ this.state.showDetails ? 'Dostosuj zgody' : 'Zgoda na Pliki Cookies' }</Title>
							<DetailsBlock>
								{ this.state.showDetails ?
									<Details
										selected={ this.state.selected }
										switchSelection={ this.selectCookieType }
									/> :
									<Informations/>
								}
							</DetailsBlock>
						</MainBlock>
						<ButtonsBlock>
							<Buttons
								acceptAllClick={ () => this.saveToStorage(true) }
								acceptSelectedClick={ () => this.saveToStorage(false) }
								isSelected={ this.state.selected.length > 1 }
								shownDetails={ this.state.showDetails }
								showDetails={ () => this.setState({ showDetails: true }) }
							/>
						</ButtonsBlock>
					</ContentBlock>
				</ThemeProvider>
			</Root>
		)
	}
}

export const renderCookiePopup = () => {
	const contentBody = document.getElementById('lPopups')
	const root = document.createElement('div')

	root.id = 'cookiesPopup'
	contentBody.appendChild(root)
	ReactDOM.render(<CookiesPopup/>, root)
}

